/* global, Promise */

/* global config */

'use strict';

angular.module('managerApp').factory('$_DevicesManagers', function (http, toast) {
    const url = config.apiURL + 'devices-managers/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        get: (id = null) => {
            let _url = url;
            if (id !== null) {
                _url += id;
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        add: (item) => {
            return http.post(url, item, {
                headers: {'Content-Type': undefined}
            }).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (id, data) => {
            if (id === null) {
                return Promise.reject('NoData');
            }
            let _url = url + id;
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        delete: (item) => {
            return http.delete(url + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },        
        addRemoveDevice: (devicesManagerId = null, deviceId = null, deviceType = null, action = null) => {
            let _url = url + devicesManagerId + '/' + deviceType + 's/' + deviceId + '/' + action;
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        disableAccount: (devicesManagerId = null) => {
            let _url = url + devicesManagerId + '/disable';
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        enableAccount: (devicesManagerId = null) => {
            let _url = url + devicesManagerId + '/enable';
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        }
    };
});

